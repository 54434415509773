@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Russo+One&display=swap');

body {
    background-color: black;
}

/* Add this to your CSS file */
.App {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
    margin: 0; /* Remove default margin */
    padding-top:0px;
  }

  
.description {
  /* display:flex;
  flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  width:70%;

}

.graph2x2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  gap: 10px;
  width: 100%;
  height: 400px;
  box-sizing: border-box;;
}


p {
  font-family: "Courier New", Courier, monospace;
  color: #00FF00; /* Bright green */
  background-color: #000000; /* Black background */
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  display: inline-block; /* Keeps it neatly contained */
}

.graph-header {
  position: absolute;
  top: -30px;
  left: 200px;
  transform: translateX(-50%);
}

h2 {
  color: #00FF00; /* Changed to a bright blue */
  font-family: "Courier New", Courier, monospace; /* Terminal-like font */
  margin: 0;
}

.navbar {
  height:50px;
  width:calc(100% - 120px);
  position: fixed;
  left:0px;
  top:0px;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-right:60px;
  margin-left:60px;
  padding-right:20px;
  padding-left:20px;
  background-color: #00FF00;
  transform: skewX(-10deg);
  z-index: 100;
}

.navbar li {
  height:20px;
  /* background-color: #00FF00; */
  color: black;
  font-weight: bold;
  padding-right:5px;
  font-size:20px;
  font-family: "Courier New", Courier, monospace;
  cursor:pointer;
  transition: all 0.3s ease;
  list-style-type: none;
}

.navbar li:hover {
  color:darkgreen;
}

.navbar h1 {
  position: fixed;
  left:calc(50% - 130px);
  top:-80px;
  font-size: 85px;
  color:black;
  font-family: "Russo One", serif;
  font-weight: bolder;
  color:rgb(0, 52, 0);
  /* text-shadow: -5px 5px #00FF00; */
  text-shadow:  
    -5px -5px #00FF00,
     5px -5px #00FF00,
    -5px  5px #00FF00,
     5px  5px #00FF00;
}

.navbar-right {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 20%;
}

.react-icon {
  font-size:25px;
}

a {
  text-decoration: none;
  color:inherit;
}

.project {
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top:50px;
}

.skills {
  display:flex;
}

.skill-item {
  background-color: #00FF00;
  padding: 10px;
  border-radius: 10px;
  color: black;
  margin: 10px;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}

.projectmanager {
  margin-top:200px;
}